<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "DevengamientosCobranzas",
  components: { PageHeader, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.views.sort(function(a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      title: enums.titles.DEVENGAMIENTOS_COBRANZAS,
      allowedActions: null,
      optionCode: enums.webSiteOptions.DEVENGAMIENTOS_COBRANZAS,
      views: [],
      showExpand: false,
      showHelp: false,
      showIcon: true
    };
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.webSiteOptions.CONCEPTOS:
            this.views.push({
              id: 1,
              title: x.title,
              order: 1,
              subtitle:
                "Conceptos para comprobantes de deuda o cobranzas y su relación con impuestos y alicuotas.",
              path: "Conceptos"
            });
            break;
          case enums.webSiteOptions.COBRADORES:
            this.views.push({
              id: 2,
              title: x.title,
              order: 2,
              subtitle: "Gestión de cobradores.",
              path: "Cobradores"
            });
            break;
          case enums.webSiteOptions.CAJEROS:
            this.views.push({
              id: 3,
              title: x.title,
              order: 3,
              subtitle: "Gestión de cajas y asignación de usuarios por caja.",
              path: "Cajeros"
            });
            break;
          case enums.webSiteOptions.CONDICIONES_IVA:
            this.views.push({
              id: 4,
              title: x.title,
              order: 4,
              subtitle: "Gestión de condiciones de IVA del agente de cuenta.",
              path: "CondicionesIva"
            });
            break;
          case enums.webSiteOptions.CUPONES_PAGO:
            this.views.push({
              id: -1,
              title: x.title,
              order: 5,
              subtitle:
                "Configuración del proceso que genera Cupones de pago para unificar la deuda de muchos comprobantes en uno solo.",
              path: "mvc",
              params: `${window.location.origin}/redirto?id=Cupon/ConfiguracionCuponPago`
            });
            break;
          case enums.webSiteOptions.DEVENGAMIENTOS_CUOTAS:
            this.views.push({
              id: 6,
              title: x.title,
              order: 6,
              subtitle:
                "Definición del proceso automático de devengamiento por Convenio y/o cobradores.",
              path: "DevengamientosCuotas"
            });
            break;
          case enums.webSiteOptions.REGLAS_COMERCIALES:
            this.views.push({
              id: 7,
              title: x.title,
              order: 7,
              subtitle:
                "Configuración de procesos que permiten aplicar, de manera automática, bonificaciones, recargos o adicionar conceptos en los devengamientos.",
              path: "ReglasComerciales"
            });
            break;
          case enums.webSiteOptions.DEVENGAMIENTO_COSEGUROS:
            this.views.push({
              id: 9,
              title: x.title,
              order: 9,
              subtitle:
                "Configuración de los comprobantes a generar en cuenta corriente por el coseguro de las solicitudes de autorización",
              path: "ConfigDevengamientoCoseguros"
            });
            break;
        }
      });
    },
    whereToPush(item) {
      if (item.id < 0) {
        this.$router.push({
          name: item.path,
          params: { url: encodeURIComponent(item.params) }
        });
      } else {
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
        this.$router.push({
          name: item.path
        });
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep .text-h5 {
  font-size: 1.35rem !important;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
